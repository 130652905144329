import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FaCaretRight } from 'react-icons/fa'

import Link from 'components/Link/Link'

import styles from './MenuItem.module.scss'

const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

const MenuItem = ({ item, selectedServiceId, selectedPage, location }) => {
  const className = cx(styles.listItem, {
    [styles.selected]:
      selectedServiceId === item.wordpress_id || selectedPage === item.slug,
  })
  return (
    <li key={item.name}>
      {item.type === 'page' ? (
        <Link className={className} to={`/${item.slug}`}>
          <span dangerouslySetInnerHTML={{ __html: item.name }} />
        </Link>
      ) : (
        <Link
          className={className}
          to={
            item.redirect_url !== 'none'
              ? item.redirect_url
              : `${location.pathname}#${item.slug}`
          }
          onClick={scrollToTop}
          replace={item.redirect_url === 'none' ? true : undefined}
        >
          <span dangerouslySetInnerHTML={{ __html: item.name }} />{' '}
          {item.redirect_url === 'none' && <FaCaretRight />}
        </Link>
      )}
    </li>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    redirect_url: PropTypes.string,
    slug: PropTypes.string.isRequired,
    wordpress_id: PropTypes.number.isRequired,
    type: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  selectedServiceId: PropTypes.number,
  selectedPage: PropTypes.string,
}

export default MenuItem
