export const getServiceFromSlug = (slug, services) =>
  services.find(service => service.slug === slug)

export const getServiceFromPageSlug = (pageSlug, services, pages) => {
  const page = pages.find(page => page.slug === pageSlug)
  const pageServiceId = page && page.service && page.service[0]
  return (
    pageServiceId &&
    services.find(service => service.wordpress_id === pageServiceId)
  )
}

// path example '/service/business-services/licensing/gambling/'
export const getServicesFromPath = (path, services) =>
  path
    .split('/')
    .filter(b => b !== '')
    .splice(1)
    .map(slug => getServiceFromSlug(slug, services))

export const getByParentService = (services, pages, index) =>
  [
    ...services.filter(service => service.wordpress_parent === index),
    ...pages
      .filter(page => page.service[0] === index)
      .map(page => ({
        ...page,
        order: page.acf.order,
        name: page.title,
      })),
  ].sort((a, b) => a.order - b.order)
