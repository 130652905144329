import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'gatsby'

import BCStyles from './Breadcrumbs.module.scss'

const Breadcrumbs = ({ home, breadcrumbs, replace, className }) => (
  <nav className={cx(BCStyles.lbhBreadCrumb, className)}>
    <ol>
      <li key="home">
        <Link to={home.url} replace={replace}>
          {home.name}
        </Link>
      </li>
      {breadcrumbs.length > 0 &&
        breadcrumbs.map(breadcrumb => (
          <li key={breadcrumb.slug}>
            <Link to={`/menu#${breadcrumb.slug}`} replace={replace}>
              {breadcrumb.name}
            </Link>
          </li>
        ))}
    </ol>
  </nav>
)

Breadcrumbs.propTypes = {
  home: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  replace: PropTypes.bool,
  className: PropTypes.string,
}

export default Breadcrumbs
