import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import MenuItem from './MenuItem'

import {
  getByParentService,
  getServicesFromPath,
  getServiceFromPageSlug,
} from 'utils/services'

import styles from './Menu.module.scss'

const BASE_LEVEL = { wordpress_id: 0 }

const Menu = ({ services, pages, location }) => {
  const { state } = location
  const [selected, setSelected] = useState([BASE_LEVEL])
  const [selectedPage, setSelectedPage] = useState()
  useEffect(() => {
    const prevPageSlug =
      state && state.prevPage && state.prevPage.replace(/\//g, '')
    const prevPageService =
      prevPageSlug && getServiceFromPageSlug(prevPageSlug, services, pages)
    setSelectedPage(prevPageService ? prevPageSlug : undefined)
    const hashtag = location.hash && location.hash.substring(1)
    const position =
      prevPageService || services.find(service => service.slug === hashtag)
    setSelected([
      BASE_LEVEL,
      ...(position ? getServicesFromPath(position.path, services) : []),
    ])
  }, [location.href])

  return (
    <div className={styles.container}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        home={{ url: '/menu', name: 'Menu' }}
        breadcrumbs={selected.slice(1)}
        replace={true}
      />
      <nav className={styles.menu}>
        <div
          className={styles.menuInner}
          style={{
            transform: `translate(-${100 * (selected.length - 1)}%)`,
          }}
        >
          {selected.map(({ wordpress_id, name }, index) => {
            const selectedServiceId =
              selected[index + 1] && selected[index + 1].wordpress_id
            return (
              <div key={wordpress_id} className={styles.column}>
                <div className={styles.listTitle}>{name || 'Services'}</div>
                <ul className={styles.itemList}>
                  {getByParentService(services, pages, wordpress_id).map(
                    item => (
                      <MenuItem
                        key={item.slug}
                        item={item}
                        selectedServiceId={selectedServiceId}
                        location={location}
                        selectedPage={selectedPage}
                      />
                    )
                  )}
                </ul>
              </div>
            )
          })}
        </div>
      </nav>
    </div>
  )
}

Menu.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      wordpress_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      redirect_url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    hash: PropTypes.string,
    state: PropTypes.shape({
      prevPage: PropTypes.string,
    }),
  }).isRequired,
}

export default Menu
