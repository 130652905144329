import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout/Layout'
import Menu from 'components/Menu/Menu'

import { flatQuery } from 'utils/queries'

const MenuPage = ({
  data: { allWordpressWpService, allWordpressPage },
  location,
}) => (
  <Layout location={location}>
    <Menu
      services={flatQuery(allWordpressWpService)}
      pages={flatQuery(allWordpressPage)}
      location={location}
    />
  </Layout>
)

MenuPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPage: PropTypes.shape({}).isRequired,
    allWordpressWpService: PropTypes.shape({}).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default MenuPage

export const pageQuery = graphql`
  query MenuIndexQuery {
    allWordpressPage(
      filter: { acf: { show_or_hide_from_menu: { eq: "show" } } }
    ) {
      edges {
        node {
          wordpress_id
          slug
          title
          service
          type
          acf {
            order
          }
        }
      }
    }
    allWordpressWpService(filter: { show_or_hide_from_menu: { eq: "show" } }) {
      edges {
        node {
          wordpress_id
          name
          slug
          wordpress_parent
          order
          redirect_service
          redirect_url
          path
        }
      }
    }
  }
`
